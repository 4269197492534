
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  CheckOutlined,
  ColumnHeightOutlined,
  EditOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import {
  addURL,
  change_apiurl_auth,
  import_apiurl,
  queryApiurl,
  recoverUrl,
  removeUrl,
  updateUrl,
} from '@/api/permission/apiurl-manage.ts';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import { useStore } from 'vuex';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import FormModal from '../../components/form-modal/form-modal.vue';
import { useForm } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const statusMap = {
  true: { text: '正常', status: 'green' },
  false: { text: '作废', status: 'red' },
};
const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    width: 50,
    dataIndex: 'index',
    slots: { customRender: 'index' },
  },
  {
    title: '名称',
    dataIndex: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'url',
    dataIndex: 'url',
  },
  {
    title: '接口对应菜单',
    dataIndex: 'menu_list',
    slots: { customRender: 'menu_list' },
  },
  {
    title: '状态',
    width: 70,
    dataIndex: 'is_active',
    slots: { customRender: 'status' },
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
  {
    title: t('操作'),
    width: 350,
    dataIndex: 'action',
    slots: { customRender: 'action' },
  },
];

export default defineComponent({
  name: 'ApiurlManage-1',
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    const editableData: any = reactive({});

    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    const modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
      is_auth: '',
      name: '',
    });
    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryApiurl, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };
    // edit
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改接口';
      editModal.form.model = {
        ...record,
      };
      currentRecord = record;
    };
    const handleDelete = (record: any) => {
      removeUrl({ org_id: current_org.id, apiurl_id: record.id }).then(res => {
        message.success('删除接口成功');
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverUrl({ org_id: current_org.id, apiurl_id: record.id }).then(res => {
        message.success('恢复接口成功');
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!editModal.form.model.id) {
        addURL({ org_id: current_org.id, ...data }).then(() => {
          message.success('新增接口成功');
          reload();
        });
      } else {
        updateUrl({
          ...data,
          org_id: current_org.id,
          apiurl_id: data.id,
        }).then((res: any) => {
          Object.assign(currentRecord, res);
          message.success('修改接口成功');
        });
      }
      editModal.visible = false;
    };
    const handlerImport = (data: any) => {
      import_apiurl({ org_id: current_org.id, ...data }).then(() => {
        importModal.visible = false;
        message.success('新增接口成功');
        reload();
      });
    };
    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '接口名称',
          label_i18n: '接口名称',
          placeholder_i18n: '请填写接口名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写接口名称',
        },
        {
          type: 'select',
          name: 'is_auth',
          label: '是否需要授权',
          label_i18n: '是否需要授权',
          placeholder_i18n: '请选择是否授权',
          disabled: false,
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择是否授权',
          datasource: 'is_auth',
          mode: 'default',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
          mode: 'default',
        },
      ],
      rules: {},
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});
    const config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '作废', value: false },
      ],
      is_auth: [
        { name: '全部', value: '' },
        { name: t('是'), value: true },
        { name: t('否'), value: false },
      ],
    };

    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '接口名称',
          label_i18n: '接口名称',
          placeholder_i18n: '请填写接口名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '接口名称',
        },
        {
          type: 'input',
          name: 'url',
          label: '绝对路径',
          label_i18n: '绝对路径',
          placeholder_i18n: '绝对路径',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '绝对路径',
        },
        {
          type: 'select',
          name: 'is_auth', // 传值字段
          label: '是否需要授权',
          label_i18n: '是否需要授权',
          placeholder_i18n: '请选择是否授权',
          disabled: false,
          labelKey: 'name', // options数据
          valueKey: 'value',
          placeholder: '请选择是否授权',
          datasource: 'is_auth', // 接参字段
          mode: 'default',
        },
      ],
      rules: {
        name: [{ required: true, message: '请输入接口名称' }],
        url: [{ required: true, message: '请输入绝对路径' }],
      },
      model: {
        id: null,
        name: '',
        url: '',
      },
    };

    const import_model_config = {
      settings: {
        name: '导入api接口json文件',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'textarea',
          name: 'api_json',
          label: '接口JSON',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '接口JSON',
        },
      ],
      rules: {
        api_json: [{ required: true, message: '请输入接口JSON' }],
      },
      model: {
        api_json: '',
      },
    };
    const importModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: import_model_config,
      options: {},
    });

    const edit_model_config_options = {
      is_auth: [
        { name: t('是'), value: true },
        { name: t('否'), value: false },
      ],
    };
    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });

    const handleChangeApiurlAuth = (record: any, is_auth: boolean) => {
      change_apiurl_auth({ org_id: current_org.id, apiurl_id: record.id, is_auth: is_auth }).then(
        (res: any) => {
          message.success(t('操作成功'));
          Object.assign(record, res);
        },
      );
    };
    const edit = (id: any) => {
      editableData[id] = state.dataSource.filter((item: any) => id === item.id)[0];
    };

    const save = (id: any) => {
      Object.assign(state.dataSource.filter((item: any) => id === item.id)[0], editableData[id]);
      updateUrl({
        org_id: current_org.id,
        apiurl_id: id,
        name: editableData[id].name,
      }).then((res: any) => {
        Object.assign(currentRecord, res);
        message.success('修改接口成功');
      });
      delete editableData[id];
    };
    return {
      statusMap,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      modelRef,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,

      // edit
      importModal,
      handlerImport,
      editModal,
      handleOpenEdit,
      edit_model_config,
      edit_model_config_options,

      config,
      config_options,
      validateInfos,

      handlerOk,
      handleDelete,
      handleRecover,

      handleChangeApiurlAuth,
      editableData,
      edit,
      save,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    SearchRender,
    FormModal,
    [Badge.name]: Badge,
    EditOutlined,
    CheckOutlined,
  },
});
